import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import DinnerContent from '../components/ContentPages/DinnerContent';

function DinnerPage({ data, location }) {
  const sanityContent = data.dinnerpage;
  return (
    <Layout location={location} lang="no">
      {sanityContent && (
        <DinnerContent sanityContent={sanityContent} lang="No" />
      )}
    </Layout>
  );
}

export const query = graphql`
  query DinnerPageNo {
    dinnerpage: sanityPage(
      id: { eq: "-f5e739b7-d2bf-5511-9350-8115d71aec6f" }
    ) {
      id
      titleNo
      description {
        _rawTextNo
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        alt
      }
      contentSections {
        ... on SanityMenu {
          id
          menuSectionsOne {
            headingNo
            menuItems {
              descriptionNo
              nameNo
              price
              allergensNo
              hidden
            }
          }
          menuSectionsTwo {
            headingNo
            menuItems {
              descriptionNo
              nameNo
              price
              allergensNo
              hidden
            }
          }
        }
      }
    }
  }
`;

export default DinnerPage;
